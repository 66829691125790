import React from "react";
import { Container } from "reactstrap";
import { TheHeader, TheFooter } from "./index";

import { envConfig } from "../configs/envConfig";

const TheLayout = ({ component: Component = null, ...props }) => {
  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <TheHeader />
        <div className="main-top-wrapper bg-blue">
        <Container className="mt-3 mb-5">
          <h1 className="main-top-title">{envConfig.siteMainTitle}</h1>
          </Container>
        </div>
        <Container className="mt-3 mb-5">
          <Component {...props} />
        </Container>
        <TheFooter />
      </div>
    </>
  );
};

export default TheLayout;

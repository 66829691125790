import React from "react";

// Public pages
const MainPage = React.lazy(() => import("../views/MainPage"));

// Add public pages (no authentication required)
export const publicRoutes = [
  // { path: "/", exact: true, name: "Home" },
  {
    path: "/main",
    exact: true,
    name: "Local Small Business Query",
    component: MainPage,
  },
];

import React from "react";
import { Container } from "reactstrap";

const TheFooter = () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container className="text-end">
        <strong>Copyright @ {new Date().getFullYear()} Metro</strong>
      </Container>
    </footer>
  );
};

export default TheFooter;

import React, { useState } from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const TheHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className="bg-dark">
      <Container>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand href="/" className="d-none d-lg-block d-md-block">
            <svg
              version="1"
              xmlns="http://www.w3.org/2000/svg"
              width="112"
              height="50"
              viewBox="0 0 133.2 60"
              role="presentation"
              focusable="false"
            >
              <path
                className="logo-circle"
                fill="#fff"
                d="M0 24.7c0-13.7 11.1-24.7 24.7-24.7s24.7 11 24.7 24.7c0 13.6-11 24.7-24.7 24.7-13.6 0-24.7-11.1-24.7-24.7z"
              ></path>
              <path
                className="logo-m"
                fill="#231F20"
                d="M32 10l-6.1 18.3 2.8 8.3c1.1-3.3 4.5-13.1 4.7-13.7v16.7h6.2v-29.6h-7.6zm-14.4 0h-7.6v29.6h6.2v-16.7c.3.8 5.8 16.7 5.8 16.7h5.5l-9.9-29.6z"
              ></path>
              <path
                className="logo-type"
                fill="#fff"
                d="M93.9 55.3c-1.3.8-1.9 1.1-2.7 1.3-.5.2-1 .2-1.7.2-1.3 0-2.5-.6-3.1-1.6-.3-.5-.4-1-.5-2.1h9.4v-1.6c0-3.9-2.5-6.5-6.1-6.5-4.1 0-7.3 3.4-7.3 7.9 0 4.3 2.8 7.1 7 7.1 1.7 0 3.2-.3 4.7-1 .3-.1.8-.4 1.5-.8h.1l-1.3-2.9zm-4.8-7.4c1.6 0 2.3.8 2.4 2.8h-5.3c.3-1.8 1.3-2.8 2.9-2.8zm-19.7 6.3l-4.9-14.3h-5.2v19.8h3.8v-12.4c.2.6 4.4 12.4 4.4 12.4h3.8c1.5-4.3 4.3-11.9 4.4-12.3v12.3h3.8v-19.8h-5.2l-4.9 14.3zm36 1.2l-.1.1c-.9.6-1.5.9-1.9.9-.4 0-.6-.3-.6-1.9v-6.1h3.1v-2.8h-3.1v-4.3c0-.3-.1-.6-.1-.6l-3.6.6v4.3c-.2 0-2.1.4-2.1.4v2.5h2.1v8.6c0 2.2.9 3.2 2.9 3.2 1 0 2.1-.3 3.2-1l.5-.3 1-.6v-.1l-1.3-2.9zm10.3-10.2c-1.1 0-2 .8-3.3 3 0-.5-.1-2.8-.1-2.8h-3.4v14.3h3.9v-6.6c0-1 .3-1.8 1-2.6.5-.7 1.1-1 1.7-1 .4 0 .7.1 1 .4l.1.1 1.2-3.7c-.7-.8-1.3-1.1-2.1-1.1zm10.3-.2c-4.1 0-7.3 3.2-7.3 7.5s3.1 7.5 7.3 7.5c4.2 0 7.3-3.2 7.3-7.5-.1-4.3-3.2-7.5-7.3-7.5zm0 11.9c-1.8 0-3.3-2-3.3-4.5 0-2.4 1.5-4.4 3.3-4.4 1.8 0 3.3 2 3.3 4.4-.1 2.5-1.5 4.5-3.3 4.5z"
              ></path>
            </svg>
          </NavbarBrand>
          <NavbarBrand href="/" className="d-lg-none d-md-none mx-auto">
            <svg
              version="1"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 50 60"
              role="presentation"
              focusable="false"
            >
              <path
                className="logo-circle"
                fill="#fff"
                d="M0 24.7c0-13.7 11.1-24.7 24.7-24.7s24.7 11 24.7 24.7c0 13.6-11 24.7-24.7 24.7-13.6 0-24.7-11.1-24.7-24.7z"
              ></path>
              <path
                className="logo-m"
                fill="#231F20"
                d="M32 10l-6.1 18.3 2.8 8.3c1.1-3.3 4.5-13.1 4.7-13.7v16.7h6.2v-29.6h-7.6zm-14.4 0h-7.6v29.6h6.2v-16.7c.3.8 5.8 16.7 5.8 16.7h5.5l-9.9-29.6z"
              ></path>
            </svg>
          </NavbarBrand>

          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-auto" navbar>
              <NavItem>
                <NavLink href="https://www.metro.net">Go to metro.net</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default TheHeader;

const currentHostname = window.location.hostname;

export const envConfig = {
  certificationType: "SBE",

  isLSBESite: currentHostname.startsWith("small")
    ? "N"
    : currentHostname.startsWith("localsmall")
    ? "Y"
    : "Y",

  siteMainTitle: currentHostname.startsWith("small")
    ? "Small Business Query"
    : currentHostname.startsWith("localsmall")
    ? "Local Small Business Query"
    : "Local Small Business Query",

  siteShortName: currentHostname.startsWith("small")
    ? "SBE"
    : currentHostname.startsWith("localsmall")
    ? "LSBE"
    : "LSBE",

  siteFullName: currentHostname.startsWith("small")
    ? "Small Business Enterprise"
    : currentHostname.startsWith("localsmall")
    ? "Local Small Business Enterprise"
    : "Local Small Business Enterprise",

};

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { publicRoutes } from "./configs/routes";
import TheLayout from "./containers/TheLayout";
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">{/* <div>Loading...</div> */}</div>
);
const NotFoundPage = lazy(() => import("./views/NotFoundPage"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Navigate to="/main" />
            }
          />
          {publicRoutes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<TheLayout component={route.component} />}
                />
              )
            );
          })}
          <Route path="*" element={<TheLayout component={NotFoundPage} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
